import { LitElement, html, nothing } from 'lit'
import { Unsubscribe } from '@reduxjs/toolkit'
import { customElement, property, state } from 'lit/decorators.js'
import { 
  fetchBookmarks, 
  postBookmark,
  deleteBookmark,
  selectIdIsBookmarked,
  selectIdIsLoading,
  selectIdHasError
} from '../redux/bookmarksReducer'
import { store } from '../redux/store'

import { PrimaryVariant } from '@web-nfb/frontend-static/design-system/wc/button/NFBButton'
import { stringToBooleanConverter } from '@web-nfb/frontend-static/design-system/wc/_utils'

@customElement('nfb-add-to-mylist')
export class NFBAddToMyList extends LitElement {
  @state()
  addedToMyList = false

  @state()
  myListIsLoaded = false

  @state()
  isLoading: boolean

  @state()
  hasError = false

  @property({ type: Number, attribute: 'registry-id' })
  registryId = null

  @property({ type: String, attribute: 'source-title' })
  sourceTitle = null

  @property({ converter: stringToBooleanConverter, attribute: 'is-dark' })
  isDark = false

  @property({ converter: stringToBooleanConverter, attribute: 'is-small' })
  isSmall = false

  @property({ attribute: 'login-url' })
  loginUrl = ''

  storeUnsubscribe: Unsubscribe

  constructor() {
    super()
    this.stateChanged = this.stateChanged.bind(this)

    // fetchBookmark should be called only once,
    // make the call if status is at its initial value
    if (store.getState().bookmarks.status === undefined) {
      store.dispatch(fetchBookmarks())
    }
  }

  connectedCallback(): void {
    super.connectedCallback()
    this.storeUnsubscribe = store.subscribe(this.stateChanged)
    this.stateChanged()
  }

  disconnectedCallback(): void {
    super.disconnectedCallback()
    this.storeUnsubscribe()
  }

  stateChanged(): void {
    const bookmarksState = store.getState().bookmarks

    if (this.registryId) {
      this.myListIsLoaded = bookmarksState.list !== null
      this.hasError = selectIdHasError(bookmarksState, this.registryId)
      this.isLoading = selectIdIsLoading(bookmarksState, this.registryId)
      this.addedToMyList = selectIdIsBookmarked(bookmarksState, this.registryId)
    }
  }

  addToMyList(): void {
    if (this.registryId) {
      store.dispatch(postBookmark(this.registryId)).then(() => {
        if (!this.hasError) this.pushDataLayerEvent('nfb_add_to_mylist')
      })
    }
  }

  removeFromMyList(): void {
    if (this.registryId) {
      store.dispatch(deleteBookmark(this.registryId)).then(() => {
        if (!this.hasError) this.pushDataLayerEvent('nfb_remove_from_mylist')
      })
    }
  }

  pushDataLayerEvent(eventName: string): void {
    if (!this.hasError) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer?.push({
        event: eventName,
        item_id: this.registryId,
        item_name: this.sourceTitle,
        nfb_version_id: this.registryId,
        nfb_version_title: this.sourceTitle
      })
    }
  }

  render() {
    if (!this.myListIsLoaded) return

    const buttonLabel =  this.addedToMyList
      ? window.gettext('Remove from My List')
      : window.gettext('Add to My List')

    return html`
      <nfb-button
        @click=${this.addedToMyList? this.removeFromMyList : this.addToMyList}
        primary-variant="${PrimaryVariant.roundWithIcon}"
        icon-name="${this.addedToMyList? 'check-mark' : 'add'}"
        is-dark="${this.isDark}"
        is-active="${this.isLoading}"
        size="${this.isSmall ? 'sm' : nothing}"
        data-ui-el="nfb-add-to-mylist-button"
        data-cy="nfb-add-to-my-list-button"
        aria-label="${buttonLabel}"
        tooltip="${buttonLabel}"
      >
      </nfb-button>
    `
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'nfb-add-to-mylist': NFBAddToMyList
  }
}
