import { configureStore } from '@reduxjs/toolkit'

import bookmarksReducer from './bookmarksReducer'
import workPageReducer from './workPageReducer'

export const store = configureStore({
  reducer: {
    bookmarks: bookmarksReducer,
    workPage: workPageReducer
  }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
