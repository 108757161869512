import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface WorkPageState {
  isPlaying: boolean
}

const initialState: WorkPageState = {
  isPlaying: false
}

export const workPageSlice = createSlice({
  name: 'workPage',
  initialState,
  reducers: {
    setWorkIsPlaying(state, action: PayloadAction<boolean>) {
      state.isPlaying = action.payload
    },
  },
  selectors: {
  }
})


export const {
  setWorkIsPlaying
} = workPageSlice.actions

export default workPageSlice.reducer
