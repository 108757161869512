import AccordionManager from '@web-nfb/frontend-static/design-system/script/elements/AccordionManager'
import CollapsableManager from '@web-nfb/frontend-static/design-system/script/elements/CollapsableManager'
import CookiesBannerManager from '@web-nfb/frontend-static/design-system/script/elements/CookiesBannerManager'
import CarouselsManager from '@web-nfb/frontend-static/design-system/script/elements/CarouselsManager'
import CardManager from '@web-nfb/frontend-static/design-system/script/elements/CardManager'
import DropdownManager from '@web-nfb/frontend-static/design-system/script/elements/DropdownManager'
import FormManager from '@web-nfb/frontend-static/design-system/script/elements/FormManager'
import LeftNavManager from '@web-nfb/frontend-static/design-system/script/elements/LeftNavManager'
import { initTooltips } from '@web-nfb/frontend-static/design-system/script/elements/TooltipManager'
import { initRibbons, destroyRibbons } from '@web-nfb/frontend-static/src/js/custom/modules/m-ribbon.js'

import '@web-nfb/frontend-static/design-system/wc/button/NFBButton'
import '@web-nfb/frontend-static/design-system/wc/icon/NFBIcon'
import '@web-nfb/frontend-static/design-system/wc/inlineMessage/NFBInlineMessage'
import './wc/NFBAddToMyList'
import '@norman/templates/elements/atecna_poll/NFBAtecnaPoll'

// Expose CarouselsManager globally 
// to initiate objects in non-module JS files (ex: for the blog carousel)
// @ts-ignore
window.CarouselsManager = CarouselsManager
// @ts-ignore
window.CardManager = CardManager

export default class App {
  accordionManager: AccordionManager | undefined
  collapsableManager: CollapsableManager | undefined
  cookiesBannerManager: CookiesBannerManager | undefined
  carouselsManager: CarouselsManager | undefined
  cardManager: CardManager | undefined
  dropdownManager: DropdownManager | undefined
  formManager: FormManager | undefined
  leftNavManager: LeftNavManager | undefined

  constructor () {
  }

  init () {
    console.log('Init app')
    this.cookiesBannerManager = new CookiesBannerManager()
    this.cookiesBannerManager.init()

    this.carouselsManager = new CarouselsManager()
    this.carouselsManager.init()

    this.cardManager = new CardManager()
    this.dropdownManager = new DropdownManager({selector: '[data-ui-el="nfb-dropdown-trigger"]'})
    this.dropdownManager.init()

    this.accordionManager = new AccordionManager()
    this.collapsableManager = new CollapsableManager()

    this.formManager = new FormManager()
    this.formManager.init()

    try {
      this.leftNavManager = new LeftNavManager()
      this.leftNavManager.init()
    } catch {}

    initTooltips()
    initRibbons()

    this.setContentAsLoaded()
  }

  setContentAsLoaded = () => {
    const layout = document.querySelector('[data-ui-el="main-layout"]') as HTMLElement
    if (layout) { layout.dataset.uiLoaded = true.toString() }
  }

  destroy () {
    console.log('Destroy app')
    this.cookiesBannerManager?.destroy()
    this.carouselsManager?.destroy()
    this.cardManager?.destroy()
    this.dropdownManager?.destroy()

    destroyRibbons()
  }
}