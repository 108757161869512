import axios, { AxiosRequestConfig } from 'axios'

declare global {
  interface Window {
    PUBLIC_API_ENDPOINT: string
  }
}

type ApiClientOptions = {
  baseURL?: string
  basePath?: string
}

abstract class ApiClient {
  declare axios
  baseURL: string
  basePath: string
  
  constructor({ basePath, baseURL }: ApiClientOptions) {
    this.baseURL = baseURL ?? ''
    this.basePath = basePath ?? ''
    this.axios = axios.create({
      baseURL: `${this.baseURL}${this.basePath}`,
    })

    this.axios.defaults.xsrfCookieName = 'csrftoken'
    this.axios.defaults.xsrfHeaderName = 'X-CSRFToken'
    this.axios.defaults.withCredentials = true
  }

  call({ method, url, data, headers, params }: AxiosRequestConfig) {
    return this.axios({
      method,
      url,
      data,
      params,
      headers
    })
  }
}

class ApiV4Client extends ApiClient {
  private static _instance: ApiV4Client = new ApiV4Client()

  private constructor() {
    super({ basePath: '/api/v4' })
  }

  static get instance () { return ApiV4Client._instance }
}

class PublicApiV5Client extends ApiClient {
  private static _instance: PublicApiV5Client = new PublicApiV5Client()

  private constructor() {
    super({ baseURL: window.PUBLIC_API_ENDPOINT, basePath: '/api/v5' })
  }

  static get instance () { return PublicApiV5Client._instance }
}

export { ApiV4Client, PublicApiV5Client }
