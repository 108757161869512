import { LitElement, html, unsafeCSS, css } from 'lit'
import { Unsubscribe } from '@reduxjs/toolkit'
import { customElement, property, query } from 'lit/decorators.js'
import { store } from '@src/js/redux/store'

// @ts-ignore
import background from './static/background.jpg'
// @ts-ignore
import perso from './static/perso.svg'

// @ts-expect-error Lit Sass import
import style from '@norman/templates/elements/atecna_poll/atecna-poll.sass?lit'
import spacings from '@web-nfb/frontend-static/design-system/sass/exports/spacing.module.sass'
import breakpoints from '@web-nfb/frontend-static/design-system/sass/exports/breakpoints.module.sass'

const SHOWN_KEY = 'atecna_poll_shown'
const MAYBE_LATER_KEY = 'atecna_poll_later'
const TIMESTART_KEY = 'atecna_time_start'

const maxWidthPopup = 478;

@customElement('nfb-atecna-poll')
export class NFBAtecnaPoll extends LitElement {

  @property({ attribute: 'poll-link' })
  pollLink: string

  @query('dialog')
  dialogEl: HTMLDialogElement

  _hasBeenShown: boolean = false
  _filmIsPlaying: boolean = false
  _delay: number = 30 * 1000
  _maybeLater: boolean = window.sessionStorage.getItem(MAYBE_LATER_KEY) === '1'
  _hasBeenPreviouslyClosed: boolean = window.localStorage.getItem(SHOWN_KEY) === '1'
  _timestart: number = parseInt(window.sessionStorage.getItem(TIMESTART_KEY) ?? '0')
  _interval: ReturnType<typeof setInterval>|null = null
  _storeUnsubscribe: Unsubscribe|null = null

  static styles = [
    style,
    css`
    .nfb-atecna-poll {
      max-width: ${Math.round(maxWidthPopup / 16)}rem;
      background: url(${unsafeCSS(background)});
      background-size: cover;
      outline: none;
      box-shadow: none;
      border: none;
      z-index: 1000;
      position: fixed;
      bottom: ${unsafeCSS(spacings['sm'])};
      right: ${unsafeCSS(spacings['sm'])};
      margin-right: 0;
      margin-left: auto;
      box-shadow: 0 0 ${unsafeCSS(spacings['sm'])} rgba(0, 0, 0, 0.5)
    }

    .nfb-atecna-poll__title {
      font-size: ${18 / 16}rem;
      line-height: ${18 / 16}rem;
    }

    .nfb-atecna-poll__content {
      font-size: ${14 / 16}rem;
    }

    .nfb-atecna-poll::after {
      background: url(${unsafeCSS(perso)});
      background-size: contain;
    }

    .nfb-atecna-poll__close {
      position: absolute;
      top: calc(1.5 * ${unsafeCSS(spacings['sm'])});
      right: calc(1.5 * ${unsafeCSS(spacings['sm'])});
    }

    @media screen and (max-width: calc(${unsafeCSS(maxWidthPopup)}px + 2 * ${unsafeCSS(spacings['sm'])})) {
      .nfb-atecna-poll {
        margin-left: ${unsafeCSS(spacings['sm'])};
      }
    }
    
    @media screen and (max-width: ${unsafeCSS(breakpoints['sm'])}) {
      .nfb-atecna-poll::after {
        display: none;
      }
    }
    `
  ]
  
  constructor() {
    super()
    this.stateChanged = this.stateChanged.bind(this)
    this.show = this.show.bind(this)
    this.onFilmStartPlaying = this.onFilmStartPlaying.bind(this)
    this.onFilmStopPlaying = this.onFilmStopPlaying.bind(this)
    this.checkTimeIsUp = this.checkTimeIsUp.bind(this)
  }

  async connectedCallback() {
    super.connectedCallback()

    // check local storage
    if (!this._hasBeenPreviouslyClosed) {

      if (!this._timestart) {
        this._timestart = Date.now()
        window.sessionStorage.setItem(TIMESTART_KEY, this._timestart.toString())
      }

      // subscribe to store to listen if a film is playing
      this._storeUnsubscribe = store.subscribe(this.stateChanged)
      this.stateChanged()

      // start timer only if user has not clicked on "maybe later"
      if (!this._maybeLater) {
        this._interval = setInterval(this.checkTimeIsUp, 1000);
      }
    }
  }

  disconnectedCallback(): void {
    super.disconnectedCallback()
    if (this._storeUnsubscribe) {
      this._storeUnsubscribe()
    }
  }

  stateChanged(): void {
    const state = store.getState()
    
    // work is playing changed
    if (this._filmIsPlaying !== state.workPage.isPlaying) {
      this._filmIsPlaying = state.workPage.isPlaying
      if (this._filmIsPlaying) {
        this.onFilmStartPlaying()
      } else {
        this.onFilmStopPlaying()
      }
    }
  }

  onFilmStartPlaying(): void {
    // Clear interval if work is playing
    // We don't want to show the popup if user is watching a film
    if (this._interval) {
      clearTimeout(this._interval)
    }
  }

  onFilmStopPlaying(): void {
    // Show modal if work is not playing anymore
    // And user does not close the modal manually
    if (!this._hasBeenPreviouslyClosed || (this._maybeLater && this._hasBeenShown)) {
      this.show()
    }
  }

  checkTimeIsUp(): void {
    if (Date.now() - this._timestart >= this._delay && this._interval) {
      clearInterval(this._interval)
      this.show()
    }
  }

  setPermanentInfo(): void {
    this._hasBeenPreviouslyClosed = true
    window.localStorage.setItem(SHOWN_KEY, '1')
    this._maybeLater = false
    window.sessionStorage.removeItem(MAYBE_LATER_KEY)
  }

  setTemporarlyInfo(): void {
    this._maybeLater = true
    window.sessionStorage.setItem(MAYBE_LATER_KEY, '1')
  }

  show(): void {
    this._interval = null
    this._hasBeenShown = true
    this.dialogEl.show()
    window.sessionStorage.removeItem(TIMESTART_KEY)
  }

  openPoll(): void {
    window.dataLayer?.push({
      event: 'nfb_platform_survey_2024',
      nfb_detail: 'yes'
    })
    this.setPermanentInfo()
  }

  closeForever(): void {
    window.dataLayer?.push({
      event: 'nfb_platform_survey_2024',
      nfb_detail: 'dismiss'
    })
    this.setPermanentInfo()
    this.dialogEl.close()
  }

  closeTemporarly(): void {
    window.dataLayer?.push({
      event: 'nfb_platform_survey_2024',
      nfb_detail: 'later'
    })
    this.setTemporarlyInfo()
    this.dialogEl.close()
  }

  render() {
    return html`
      <dialog class="nfb-atecna-poll">
        <div class="nfb-atecna-poll__container">
          <nfb-button
            class="nfb-atecna-poll__close"
            @click="${this.closeForever}"
            icon-name="delete"
            primary-variant="round-with-icon"
            size="sm"
          ></nfb-button>

          <h2 class="nfb-atecna-poll__title">
            ${window.gettext('We need your help!')}
          </h2>

          <p class="nfb-atecna-poll__content">
            ${window.gettext('Fill out a short survey to influence the future of NFB.ca and its apps. It should only take a few minutes.')}
          </p>

          <div class="nfb-atecna-poll__button-container">
            <nfb-button
              @click="${this.openPoll}"
              text="${window.gettext('I’m in!')}"
              is-dark="true"
              link="${this.pollLink}"
            ></nfb-button>
            <nfb-button
              @click="${this.closeTemporarly}"
              text="${window.gettext('Maybe later')}"
              secondary-variant="discreet"
            ></nfb-button>
          </div>
        </div>
      </dialog>
    `
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'nfb-atecna-poll': NFBAtecnaPoll
  }
}
